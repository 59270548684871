<template>
	<div>
		<b-modal
			id="modal-update-department"
			cancel-variant="outline-secondary"
			:ok-title="$t('Save change')"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			:hide-footer="!$can('update','department')"
			centered
			size="md"
			:title="$t('Update department')"
			@ok="confirmUpdateDepartment"
			@hidden="resetModal()"
		>
			<!-- form -->
			<validation-observer ref="updateDepartment">
				<b-form
					ref=""
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationFormUpdateDepartment"
				>
					<!-- Field: Name -->
					<b-form-group
						label-for="name"
					>
						<label class="mb-1"><span class="text-danger">*</span> {{ $t('Name') }}</label>
						<validation-provider
							#default="{ errors }"
							name="departmentName"
							rules="required"
						>
							<b-form-input
								id="name"
								v-model="departmentData.name"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<!-- Description -->
					<b-form-group
						label-for="description"
					>
						<label class="mb-1">{{ $t('Description') }}</label>
						<b-form-input
							id="description"
							v-model="departmentData.description"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						/>

					</b-form-group>

					<!-- Status -->
					<b-form-group
						label-for="status"
					>
						<label class="mb-1 d-block">{{ $t('Status') }}</label>
						<b-form-radio
							v-model="departmentData.status"
							name="status"
							value="1"
							inline
						>
							{{ $t('Active') }}
						</b-form-radio>
						<b-form-radio
							v-model="departmentData.status"
							name="status"
							value="2"
							inline
						>
							{{ $t('Inactive') }}
						</b-form-radio>
					</b-form-group>

				</b-form>
			</validation-observer>

		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BFormRadio, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import memberStoreModule from '@/views/apps/member/memberStoreModule'

export default {
	components: {
		BFormRadio, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		departmentDatas: {
			type: Object,
			default: null,
		}
	},
	data() {
		return {
			departmentData: {
				id: null,
				name: null,
				description: null,
				status: 1,
			},
		}
	},
	watch: {
		departmentDatas: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.departmentData.id = newVal.id
					this.departmentData.name = newVal.name
					this.departmentData.description = newVal.description
					this.departmentData.status = newVal.status
				}
			}
		}
	},
	/* mounted() {
		this.departmentData.id = this.departmentDatas.id
		this.departmentData.name = this.departmentDatas.name
		this.departmentData.description = this.departmentDatas.description
	}, */
	created() {
	},
	destroyed() {
	},
	methods: {
		validationFormUpdateDepartment() {
			this.$refs.updateDepartment.validate().then(success => {
				if (success) {
					store.dispatch('app-member/updateDepartment', this.departmentData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code === '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$emit('refetch-data')
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
								this.$nextTick(() => {
									setTimeout(() => {
										this.$bvModal.hide('modal-update-department')
									}, '500')
									setTimeout(() => {
										this.resetModal()
									}, '1000')
								})
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		resetModal() {
			this.$emit('update:departmentDatas', null)
			this.departmentData.id = ''
			this.departmentData.name = ''
			this.departmentData.description = ''
			this.departmentData.status = 1
		},
		confirmUpdateDepartment(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormUpdateDepartment()
		}
	},
	setup() {
		if (!store.hasModule('app-member')) store.registerModule('app-member', memberStoreModule)
		return {
		}
	},
}
</script>